/* eslint-disable react/no-array-index-key */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

// Third Party
import styled from 'styled-components'

const StyledThreeFishPlates = styled.div`
  justify-content: space-between;

  @media screen and (max-width: 767px) {
    justify-content: center;
  }
`

const ThreeFishPlates = ({ className }) => {
  const {
    visschotels: {
      edges: visschotels
    }
  } = useStaticQuery(graphql`{
    visschotels: allWordpressWpVisschotel(limit: 3) {
      edges {
        node {
          title
          slug
          acf {
            image {
              localFile {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 600) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }`)

  return (
    <StyledThreeFishPlates className={`${className ? `${className}` : ``} flex-wrap`}>
      {visschotels.map((schotel, index) => (
        <Visschotel key={index} schotel={schotel} />
      ))}
    </StyledThreeFishPlates>
  )
}

export default ThreeFishPlates

const StyledVisschotel = styled.div``

const StyledImg = styled(Img)`
  width: 360px;
  height: 275px;

  @media screen and (max-width: 1199px) {
    width: 300px;
    height: 160px;
  }

  @media screen and (max-width: 991px) {
    width: 220px;
    height: 120px;
  }

  @media screen and (max-width: 767px) {
    width: 300px;
    height: 160px;
  }
`

const VisschotelContent = styled.div`
  height: 119px;

  h2 {
    position: relative;
    font-size: ${props => props.theme.font.size.xxml};

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: ${props => props.theme.color.light};
    }
  }

  @media screen and (max-width: 1199px) {
    height: 100px;

    h2 {
      font-size: ${props => props.theme.font.size.xxl};
    }
  }


  @media screen and (max-width: 991px) {
    height: 80px;

    h2 {
      font-size: ${props => props.theme.font.size.xl};
    }
  }
`

const Visschotel = ({ className, schotel: { node: schotel } }) => (
  <StyledVisschotel className={`pb-4 pb-lg-0 ${className ? `${className}` : ``}`}>
    <StyledImg fluid={schotel.acf.image.localFile.childImageSharp.fluid} alt="" />
    <VisschotelContent className="flex-column d-flex justify-content-between color-background-contrast px-4 py-3">
      <h2 className="mb-3 color-text-light font-family-secondary">{schotel.title}</h2>
    </VisschotelContent>
  </StyledVisschotel>
)