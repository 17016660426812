/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import ButtonDefault from 'components/elements/ButtonDefault'
import ThreeFishPlates from 'components/shared/ThreeFishPlates'
import Products from 'components/shared/Products'

// Third Party
import styled from 'styled-components'

const ImgContainer = styled.div`
  height: 500px;

  @media screen and (max-width: 991px) {
    height: 250px;
  }
`

const StyledImg = styled.img`
  position: absolute;
  top: -150px;
  left: -200px;
  z-index: -1;

  @media screen and (max-width: 991px) {
    left: -80px;
    top: -50px;
    height: 300px;
    opacity: 0.8;
  }
`

const Title = styled.div`
  h2 {
    font-size: ${props => props.theme.font.size.xxxxl};

    @media screen and (max-width: 991px) {
      font-size: ${props => props.theme.font.size.xxxl};
    }
  }
`

const Assortiment = styled.div`
  margin-top: 275px;

  @media screen and (max-width: 991px) {
    margin-top: 50px;
  }
`

const AssortimentTemplate = ({
  data: {
    page: { 
      path,
      acf: {
        ourFishplates_group: ourFishPlatesGroup
      },
      yoastMeta 
    },
  },
  location
}) => {

  setTimeout(() => {
    if (typeof document !== "undefined") {
      if (location.hash !== "") {
        document.getElementById(location.hash).scrollIntoView({behavior: "smooth"});
      }
    }
  }, 300)
 
  
  return (
    <Layout>
      <SEO yoast={{ meta: yoastMeta }} pathname={path} article />
      <Assortiment className="container py-5">

        <Title className="d-lg-flex d-none justify-content-center">
          <h2 className="mb-0 font-family-secondary">{ourFishPlatesGroup.title}</h2>
        </Title>
        <ThreeFishPlates className="d-lg-flex d-none pb-5" />

        <Title className="d-lg-none d-flex justify-content-center">
          <h2 className="mb-0 font-family-secondary">Assortiment</h2>
        </Title>
        <Products location={location} className="py-lg-5 py-3" />

        <div className="d-lg-none py-4 d-flex justify-content-center">
          <ButtonDefault to={ourFishPlatesGroup.link.url}>
            <p className="mb-0 font-size-m">{ourFishPlatesGroup.link.title}</p>
          </ButtonDefault>
        </div>

        <Title className="d-lg-none d-flex justify-content-center">
          <h2 className="mb-0 font-family-secondary">{ourFishPlatesGroup.title}</h2>
        </Title>
        <ThreeFishPlates className="d-lg-none d-flex pb-5" />

        <div className="d-flex justify-content-center">
          <ButtonDefault to={ourFishPlatesGroup.link.url}>
            <p className="mb-0 font-size-m">{ourFishPlatesGroup.link.title}</p>
          </ButtonDefault>
        </div>

        <ImgContainer className="position-relative">
          <StyledImg src={ourFishPlatesGroup.image.localFile.publicURL} alt="" />
        </ImgContainer>
      </Assortiment>
    </Layout>
  )
}

export default AssortimentTemplate

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path
      acf {
        ourFishplates_group {
          title
          link {
            title
            url
            target
          }
          image {
            localFile {
              publicURL
            }
          }
        }
      }
      yoastMeta: yoast_meta {
        name
        content
        property
      }
    }
  }
`