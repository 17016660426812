/* eslint-disable react/no-children-prop */
import React from 'react'

// Components
import GerookteVis from 'components/shared/products/GerookteVis'
import GebakkenVis from 'components/shared/products/GebakkenVis'
import VerseVisFilets from 'components/shared/products/VerseVisFilets'
import HeleVerseVis from 'components/shared/products/HeleVerseVis'
import Garnalen from 'components/shared/products/Garnalen'
import Roerbakschotels from 'components/shared/products/Roerbakschotels'
import Salades from 'components/shared/products/Salades'
import Sauzen from 'components/shared/products/Sauzen'

// Images
import chevronRight from 'img/chevron_right.svg'

// Third Party
import styled, { css } from 'styled-components'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
    AccordionItemState,
} from 'react-accessible-accordion'
import 'react-accessible-accordion/dist/fancy-example.css'

const StyledAccordion = styled(Accordion)``

const StyledAccordionItem = styled(AccordionItem)``

const StyledAccordionItemHeading = styled(AccordionItemHeading)`
  display: flex;
  justify-content: center;
`

const Icon = styled.img`

`

const IconContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 30px;
  padding: 15px;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  border-radius: 100px;
  border: 1px solid ${props => props.theme.color.main};
  transition: 0.25s;
  height: 50px;
  width: 50px;

  @media screen and (max-width: 991px) {
    height: 40px;
    width: 40px;
  }

  ${props => props.open && css`
    transform: rotate(90deg);
  `}
`

const StyledAccordionItemButton = styled(AccordionItemButton)`
  display: flex;
  justify-content: center;
  position: relative;

  width: 800px;

  outline: none;
  box-shadow: none;

  h2 {
    position: relative;
    cursor: pointer;
    font-size: ${props => props.theme.font.size.xxml};

    @media screen and (max-width: 991px) {
      font-size: ${props => props.theme.font.size.xxl};
    }

    ${props => props.bottomline && css`
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        margin-right: auto;
        margin-left: auto;
        width: 800px;
        height: 2px;
        background-color: ${props.theme.color.main};

        ${props.open && css`
          display: none;
        `}

        @media screen and (max-width: 991px) {
          display: none;
        }
      }
    `}

    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      margin-right: auto;
      margin-left: auto;
      width: 800px;
      height: 2px;
      background-color: ${props => props.theme.color.main};

      @media screen and (max-width: 991px) {
        width: 95%;
      }
    }
  }
`

const StyledAccordionItemPanel = styled(AccordionItemPanel)`
  transition: 0.25s;
`

const StyledProducts = styled.div``

const Products = ({ location, className }) => {
  const openList = []

  if (location.hash) {
    openList.push(location.hash)
  }
  
  return (
    <StyledProducts className={`${className ? `${className}` : ``}`}>
      <StyledAccordion preExpanded={openList} allowMultipleExpanded allowZeroExpanded>

        <div id="#versevisfilets" />
        <StyledAccordionItem uuid="#versevisfilets">
          <StyledAccordionItemHeading>
            <StyledAccordionItemButton>
              <AccordionItemState children={data => (
                <div className="w-100">
                  <h2 className="w-100 text-center mb-0 py-3 font-family-secondary">Verse visfilets</h2>
                  <IconContainer open={data.expanded}>
                    <Icon src={chevronRight} alt="" />
                  </IconContainer>
                </div> 
                )} 
              />
            </StyledAccordionItemButton>
          </StyledAccordionItemHeading>
          <StyledAccordionItemPanel>
            <VerseVisFilets className="py-lg-4 py-1" />
          </StyledAccordionItemPanel>
        </StyledAccordionItem>
        
        <div id="#heleversevis" />
        <StyledAccordionItem uuid="#heleversevis">
          <StyledAccordionItemHeading>
            <StyledAccordionItemButton>
              <AccordionItemState children={data => (
                <div className="w-100">
                  <h2 className="w-100 text-center mb-0 py-3 font-family-secondary font-size-xxml">Hele verse vis</h2>
                  <IconContainer open={data.expanded}>
                    <Icon src={chevronRight} alt="" />
                  </IconContainer>
                </div> 
                )} 
              />
            </StyledAccordionItemButton>
          </StyledAccordionItemHeading>
          <StyledAccordionItemPanel>
            <HeleVerseVis className="py-lg-4 py-1" />
          </StyledAccordionItemPanel>
        </StyledAccordionItem>

        <div id="#garnalen" />
        <StyledAccordionItem uuid="#garnalen">
          <StyledAccordionItemHeading>
            <StyledAccordionItemButton>
              <AccordionItemState children={data => (
                <div className="w-100">
                  <h2 className="w-100 text-center mb-0 py-3 font-family-secondary font-size-xxml">Garnalen</h2>
                  <IconContainer open={data.expanded}>
                    <Icon src={chevronRight} alt="" />
                  </IconContainer>
                </div> 
                )} 
              />
            </StyledAccordionItemButton>
          </StyledAccordionItemHeading>
          <StyledAccordionItemPanel>
            <Garnalen className="py-lg-4 py-1" />
          </StyledAccordionItemPanel>
        </StyledAccordionItem>

        <div id="#roerbakschotels" />
        <StyledAccordionItem uuid="#roerbakschotels">
          <StyledAccordionItemHeading>
            <StyledAccordionItemButton>
              <AccordionItemState children={data => (
                <div className="w-100">
                  <h2 className="w-100 text-center mb-0 py-3 font-family-secondary font-size-xxml">Roerbakschotels</h2>
                  <IconContainer open={data.expanded}>
                    <Icon src={chevronRight} alt="" />
                  </IconContainer>
                </div> 
                )} 
              />
            </StyledAccordionItemButton>
          </StyledAccordionItemHeading>
          <StyledAccordionItemPanel>
            <Roerbakschotels className="py-lg-4 py-1" />
          </StyledAccordionItemPanel>
        </StyledAccordionItem>

        <div id="#gerooktevis" />
        <StyledAccordionItem uuid="#gerooktevis">
          <StyledAccordionItemHeading>
            <StyledAccordionItemButton>
              <AccordionItemState children={data => (
                <div className="w-100">
                  <h2 className="w-100 text-center mb-0 py-3 font-family-secondary font-size-xxml">Gerookte vis</h2>
                  <IconContainer open={data.expanded}>
                    <Icon src={chevronRight} alt="" />
                  </IconContainer>
                </div> 
                )} 
              />
            </StyledAccordionItemButton>
          </StyledAccordionItemHeading>
          <StyledAccordionItemPanel>
            <GerookteVis className="py-lg-4 py-1" />
          </StyledAccordionItemPanel>
        </StyledAccordionItem>

        <div id="#salades" />
        <StyledAccordionItem uuid="#salades">
          <StyledAccordionItemHeading>
            <StyledAccordionItemButton>
              <AccordionItemState children={data => (
                <div className="w-100">
                  <h2 className="w-100 text-center mb-0 py-3 font-family-secondary font-size-xxml">Salades</h2>
                  <IconContainer open={data.expanded}>
                    <Icon src={chevronRight} alt="" />
                  </IconContainer>
                </div> 
                )} 
              />
            </StyledAccordionItemButton>
          </StyledAccordionItemHeading>
          <StyledAccordionItemPanel>
            <Salades className="py-lg-4 py-1" />
          </StyledAccordionItemPanel>
        </StyledAccordionItem>

        <div id="#gebakkenvis" />
        <StyledAccordionItem uuid="#gebakkenvis">
          <StyledAccordionItemHeading>
            <StyledAccordionItemButton>
              <AccordionItemState children={data => (
                <div className="w-100">
                  <h2 className="w-100 text-center mb-0 py-3 font-family-secondary font-size-xxml">Gebakken vis</h2>
                  <IconContainer open={data.expanded}>
                    <Icon src={chevronRight} alt="" />
                  </IconContainer>
                </div> 
                )} 
              />
            </StyledAccordionItemButton>
          </StyledAccordionItemHeading>
          <StyledAccordionItemPanel>
            <GebakkenVis className="py-lg-4 py-1" />
          </StyledAccordionItemPanel>
        </StyledAccordionItem>

        <div id="#sauzen" />
        <StyledAccordionItem uuid="#sauzen">
          <StyledAccordionItemHeading>
            <AccordionItemState children={data => (
              <StyledAccordionItemButton open={data.expanded} bottomline="true">
                <div className="w-100">
                  <h2 className="w-100 text-center mb-0 py-3 font-family-secondary font-size-xxml">Sauzen</h2>
                  <IconContainer open={data.expanded}>
                    <Icon src={chevronRight} alt="" />
                  </IconContainer>
                </div> 
              </StyledAccordionItemButton>
              )} 
            />
          </StyledAccordionItemHeading>
          <StyledAccordionItemPanel>
            <Sauzen className="py-lg-4 py-1" />
          </StyledAccordionItemPanel>
        </StyledAccordionItem>

      </StyledAccordion>
    </StyledProducts>
  )
}

export default Products