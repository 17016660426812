/* eslint-disable react/no-array-index-key */
import React from 'react'

// Components
import AssortimentItem from 'components/shared/AssortimentItem'

// Third Party
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

const StyledSauzen = styled.div`
  padding-left: 100px;
  padding-right: 100px;

  @media screen and (max-width: 991px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`

const Sauzen = ({ className }) => {
  const {
    list: {
      edges: itemList
    }
  } = useStaticQuery(graphql`{
    list: allWordpressWpProduct(filter: {product_type: {eq: 12}}, sort: {fields: date}) {
      edges {
        node {
          title
        }
      }
    }
  }`)

  return (
    <StyledSauzen className={`row ${className ? `${className}` : ``}`}>
      {itemList.map((vis, index) => (
        <AssortimentItem className="col-lg-6 d-flex py-2 justify-content-center" item={vis} key={index} />
      ))}
    </StyledSauzen>
  )
}

export default Sauzen