import React from 'react'

// Third Party
import styled from 'styled-components'

const StyledItem = styled.div`
  h2 {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      margin-right: auto;
      margin-left: auto;
      width: 100px;
      height: 1px;
      background-color: ${props => props.theme.color.secondary};
    }
  }
`

const AssoritmentItem = ({ className, item: { node: item } }) => (
  <StyledItem className={`${className ? `${className}` : ``}`}>
    <h2 className="pb-3 w-100 text-center font-size-xm color-text-contrast text-uppercase">{item.title}</h2>
  </StyledItem>
)

export default AssoritmentItem